import api from '@/api'
import urls from '@/utils/urls'

export default {
  getLectures: (gradeId) => api.get(
    `${
      urls.CONTENT_PROGRAM_BASE_URL
    }/grades/${gradeId}/lectures`,
  ),
  getLecturesByGrades: (gradeIds) => api.get(
    `${
      urls.CONTENT_PROGRAM_BASE_URL
    }/lectures/by-grades`, {
      params: {
        gradeIds,
      },
    },
  ),
  getLecturesByArea: (areaId) => api.get(
    `${urls.SCHOOL_BFF}/knowledge-areas/${areaId}/lectures`,
    {
      params: {
        isItinerary: true,
      },
    },
  ),
}
