import shifts from '@/utils/enums/shifts'

export const getSegmentNameByGradeId = (gradeId) => {
  const kindergartenI = 9 // Infantil II
  const kindergartenII = 10 // Infantil III
  const kindergartenIII = 11 // Infantil IV
  const kindergartenIV = 12 // Infantil V
  const elementarySchool1 = 6 // 1º ano
  const elementarySchool2 = 7 // 2º ano
  const elementarySchool3 = 8 // 3º ano
  const elementarySchool4 = 13 // 4º ano
  const elementarySchool5 = 2 // 5º ano
  const elementarySchool6 = 3 // 6º ano
  const elementarySchool7 = 14 // 7º ano
  const elementarySchool8 = 15 // 8º ano
  const elementarySchool9 = 16 // 9º ano
  const highSchool1 = 17 // 1ª série
  const highSchool2 = 5 // 2ª série
  const preUni = 4 // Pré-Universitário
  const preEntranceExam = 18 // Pré-Vestibular
  const semi = 19 // SEMI
  const semi1 = 20 // Semiextensivo 1º Semestre
  const semi2 = 22 // Semiextensivo 2º Semestre

  const earlyYears = [
    kindergartenI,
    kindergartenII,
    kindergartenIII,
    kindergartenIV,
  ]
  const elementary = [
    elementarySchool1,
    elementarySchool2,
    elementarySchool3,
    elementarySchool4,
    elementarySchool5,
    elementarySchool6,
    elementarySchool7,
    elementarySchool8,
    elementarySchool9,
  ]
  const pre = [
    highSchool1,
    highSchool2,
    preUni,
    preEntranceExam,
    semi,
    semi1,
    semi2,
  ]

  if (earlyYears.includes(gradeId)) {
    return 'Anos iniciais'
  }

  if (elementary.includes(gradeId)) {
    return 'Ensino Médio'
  }

  if (pre.includes(gradeId)) {
    return 'Pré-vestibular'
  }

  return '--'
}

export const removeAccents = (s) => {
  const accentMap = {
    á: 'a',
    ã: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    õ: 'o',
    ú: 'u',
  }

  if (!s) {
    return ''
  }

  let ret = ''

  for (let i = 0; i < s.length; i += 1) {
    ret += accentMap[s.charAt(i)] || s.charAt(i)
  }

  return ret
}

export const clearEmptyParams = (value) => value
  .replace(/[^=&]+=(&|$)/g, '')
  .replace(/&$/, '')

export const removeEmptyKeysValues = (obj) => {
  const newObj = {}

  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] !== 'undefined'
      && obj[key] !== null
      && obj[key] !== ''
      && obj[key].length > 0
    ) {
      newObj[key] = obj[key]
    }
  })

  return newObj
}

export const hasOwnProperty = (object, prop) => Object
  .prototype
  .hasOwnProperty
  .call(object, prop)

export const shiftTranslate = (shift) => shifts[shift] || shift || ''
