import authService from '@/api/auth'

import * as types from './types'

export default {
  async getServerDate({ commit }) {
    try {
      const response = await authService.getServerDate()

      commit(types.SET_SERVER_DATE, new Date(`${response.data}T00:00:00`))
    } catch {
      commit(types.SET_SERVER_DATE, new Date())
    }
  },
}
