import api from '@/api'
import urls from '@/utils/urls'

const baseURL = urls.SCHOOL_BFF
export default {
  getAll: () => api.get('grades'),
  getGrades: () => api.get(`${baseURL}/school-groups/grades`),
  getGradesUser: (year) => {
    const yearLength = 4
    let params

    if (year && String(year).trim().length === yearLength) {
      params = { year }
    }

    return api.get('contracts', { params })
  },
}
