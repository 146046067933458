import api from '@/api'
import { clearEmptyParams } from '@/utils'
import urls from '@/utils/urls'

const buildFilters = (filters) => {
  const queryString = {
    filter: filters.filter || '',
    year: filters.year || '',
    sort: filters.sort || '',
    size: filters.size || '',
    direction: filters.direction || '',
    page: filters.page || '',
  }

  const queryStrings = clearEmptyParams(Object.keys(queryString)
    .map((key) => `${key}=${queryString[key]}`).join('&'))

  const body = {
    grades: filters.grades,
    shifts: filters.shifts,
    classrooms: filters.classrooms,
    status: filters.status,
    reallocate: filters.reallocate || false,
  }

  return {
    queryStrings,
    body,
  }
}

export default {
  getAll: (filters) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      /* `${urls.SCHOOL_BFF}/students/search?${resultFilters.queryStrings}`, */
      `${urls.SCHOOL_API}/students/search?${resultFilters.queryStrings}`,
      resultFilters.body,
    )
  },
  createBulk: (students) => api.post('students/bulk', students),
  exportXls: (students) => api.post(
    'export/student',
    students,
    {
      responseType: 'blob',
    },
  ),
  exportXlsFiltered: (filters) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `export/student/search?${resultFilters.queryStrings}`,
      resultFilters.body,
      {
        responseType: 'blob',
      },
    )
  },
  exportAccessKey: (students) => api({
    method: 'post',
    url: 'export/student/accessKeyPdf',
    data: students,
    responseType: 'blob',
  }),
  exportAccesKeyFiltered: (filters) => api({
    url: 'export/student/accessKeyPdf/search',
    method: 'post',
    data: {
      grades: filters.grades,
      shifts: filters.shifts,
      classrooms: filters.classrooms,
      status: filters.status,
      reallocate: filters.reallocate || false,
    },
    params: {
      filter: filters.filter,
      year: filters.year,
      sort: filters.sort,
      size: filters.size,
      direction: filters.direction,
      page: filters.page,
    },
    responseType: 'blob',
  }),
  exportDefaultWorksheet: () => api.get(
    'export/student/defaultSpreadSheet',
    {
      responseType: 'blob',
    },
  ),
  update: ({
    id,
    classroomId,
    name,
    inscriptionKey,
    hasSpecialNeeds,
    login,
  }) => api.put(`students/${id}`, {
    classroomId,
    name,
    inscriptionKey,
    hasSpecialNeeds,
    login,
  }),
  bulkStudentUpdate: (payload) => api.put('students/bulk', payload),
  importWorksheet: (file) => {
    const formData = new FormData()

    formData.append('content', file)

    return api.post(
      'import/students',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },
  delete: (studentId) => api.delete(`students/${studentId}`),
}
