export default {
  classrooms: (state) => state.classrooms,
  classroomById: (state) => state.classroomById,
  loadingClassrooms: (state) => state.loadingClassrooms,
  paginatedClassrooms: (state) => state.paginatedClassrooms,
  paginatedClassroomsStudents: (state) => state.paginatedClassroomsStudents,
  paginatedClassroomsTeachers: (state) => state.paginatedClassroomsTeachers,
  updatedClassroom: (state) => state.updatedClassroom,
  importClassroomResult: (state) => state.importClassroomResult,
  importClassroomResultErrorList: (state) => state
    .importClassroomResult
    .errorList,
}
