export const SET_STUDENTS_PAGED = 'SET_STUDENTS_PAGED'
export const SET_STUDENTS_UPDATED = 'SET_STUDENTS_UPDATED'
export const SET_SHOW_STUDENTS_IMPORT = 'SET_SHOW_STUDENTS_IMPORT'
export const SET_TYPE_UPDATE = 'SET_TYPE_UPDATE'
export const SET_LOADING_STUDENTS = 'SET_LOADING_STUDENTS'
export const SET_SELECT_STUDENT = 'SET_SELECT_STUDENT'
export const SET_RESET_STUDENT = 'SET_RESET_STUDENT'
export const SET_REMOVE_STUDENT = 'SET_REMOVE_STUDENT'
export const SET_NEW_LIST = 'SET_NEW_LIST'
export const SET_UPDATE_IMPORT_CONFLICT = 'SET_UPDATE_IMPORT_CONFLICT'
export const DELETE_IMPORT_STUDENT_CONFLICT = 'DELETE_IMPORT_STUDENT_CONFLICT'
export const SET_SPECIFIC_IMPORT_CONFLICT = 'SET_SPECIFIC_IMPORT_CONFLICT'
export const SET_RESOLVE_IMPORT_CONFLICT = 'SET_RESOLVE_IMPORT_CONFLICT'
export const SET_RESET_IMPORT_CONFLICT = 'SET_RESET_IMPORT_CONFLICT'
export const DELETED_STUDENT_ID = 'DELETED_STUDENT_ID'
