import axios from 'axios'

import { deleteCookie, getCookie, setCookie } from './cookies'
import urls from './urls'

const tokenCookieName = 'token'
const refreshTokenCookieName = 'refreshToken'
const sessionCookieName = 'session'
const authDomain = process.env.VUE_APP_COOKIE_DOMAIN
const cookiesConfig = { domain: authDomain }

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const getAuthToken = () => getCookie(tokenCookieName)
export const getAuthSession = () => getCookie(sessionCookieName)
export const getRefreshToken = () => getCookie(refreshTokenCookieName)
export const hasRefreshToken = () => !!getRefreshToken()
export const isAuthenticated = () => !!getAuthToken() || hasRefreshToken()

export const setAuthCookies = ({ token, refreshToken, sessionToken }) => {
  const THIRTY_DAYS_IN_SECONDS = 2592000
  const bearerToken = token.startsWith('Bearer') ? token : `Bearer ${token}`

  setCookie(tokenCookieName, bearerToken, cookiesConfig)
  setCookie('strategy', 'token', cookiesConfig)
  setCookie(sessionCookieName, sessionToken, cookiesConfig)
  setCookie(refreshTokenCookieName, refreshToken, {
    'max-age': THIRTY_DAYS_IN_SECONDS,
    domain: authDomain,
  })
}

export const clearAuthTokens = () => {
  deleteCookie(tokenCookieName, cookiesConfig)
  deleteCookie('strategy', cookiesConfig)
  deleteCookie(sessionCookieName, cookiesConfig)
  deleteCookie(refreshTokenCookieName, cookiesConfig)
}

export const logout = ({ redirectBack } = { redirectBack: false }) => {
  const redirectToLogin = () => {
    const portalURL = urls.PORTAL_URL
    const { href } = window.location
    const normalizedURL = (href.endsWith('/') ? href.substr(0, href.length - 1) : href)
      .split('?')[0]

    let params = ''

    if (redirectBack && normalizedURL !== portalURL && normalizedURL !== `${portalURL}/login`) {
      params += `?redirect=${encodeURIComponent(href)}`
    }

    window.location.assign(`${portalURL}${params}`)
  }

  clearAuthTokens()
  redirectToLogin()
}

export const refreshAuthTokens = (() => {
  let refreshTokenPromise = null

  return async () => {
    // Prevent refreshToken to be called multiple times
    if (refreshTokenPromise instanceof Promise) {
      return refreshTokenPromise
    }

    refreshTokenPromise = axios.post(
      `${urls.AUTH_API}/v2/auth/refreshToken`,
      { token: getRefreshToken() },
    )

    const response = await refreshTokenPromise

    setAuthCookies(response.data.authResponse)

    // clear promise
    refreshTokenPromise = null

    return Promise.resolve(response)
  }
})()

export const trustedDomains = [
  'portalsas.com.br',
  'saseducacao.com.br',
]

if (process.env.VUE_APP_MODE === 'development') {
  trustedDomains.push('localhost')
}

export const isTrustedDomain = (url) => {
  try {
    const trustedDomainsRegex = new RegExp(trustedDomains.join('|'))
    const { hostname } = url instanceof URL ? url : new URL(url)

    return trustedDomainsRegex.test(hostname)
  } catch {
    return false
  }
}
