import gradesService from '@/api/grades/gradesService'
import teacherService from '@/api/teacher/teacherService'

import * as types from './types'

export default {
  getGradesUser: async ({ commit }, year) => {
    const { data } = await gradesService.getGradesUser(year)

    if (data) {
      const LAST_FUNDAMENTAL_LEVEL = 12
      const FIRST_FUNDAMENTAL_LEVEL = 3

      const gradesUser = data.map(
        (model) => {
          let group = 'Ensino Infantil'
          if (
            model.order > FIRST_FUNDAMENTAL_LEVEL
            && model.order <= LAST_FUNDAMENTAL_LEVEL
          ) {
            group = 'Ensino Fundamental'
          } else if (model.order > LAST_FUNDAMENTAL_LEVEL) {
            group = 'Ensino Médio'
          }

          return {
            value: model.gradeId,
            text: model.gradeName,
            order: model.order,
            group,
          }
        },
      ).sort((a, b) => (a.order > b.order ? 1 : -1))

      commit(types.GRADES_USER, gradesUser)

      return gradesUser
    }

    return false
  },
  getLectures: async ({ commit }) => {
    const { data: lectures } = await teacherService.getLectures()

    lectures.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }

      return 0
    })

    lectures.sort()

    commit(types.LECTURES, lectures)

    return lectures
  },
}
