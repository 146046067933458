import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const onboarding = {
  users: {},
}

const state = {
  onboarding: JSON.parse(
    localStorage.getItem('@onboarding/pdc'),
  ) || onboarding,
}

export default {
  state,
  getters,
  mutations,
  actions,
}
