export default {
  loadingStudents: (state) => state.loadingStudents,
  studentsPaged: (state) => state.studentsPaged,
  selectedStudents: (state) => state.selectedStudents,
  importStudentResult: (state) => state.importStudentResult,
  importStudentResultErrorList: (state) => state.importStudentResult.errorList,
  studentsUpdated: (state) => state.studentsUpdated,
  typeUpdate: (state) => state.typeUpdate,
  deletedStudentId: (state) => state.deletedStudentId,
  showStudentImport: (state) => state.showStudentImport,
}
