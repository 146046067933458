import teacherService from '@/api/teacher/teacherService'

import * as types from './types'

export default {
  getTeachersPaged: async ({ commit }, filters) => {
    commit(types.SET_LOADING_TEACHER, true)

    try {
      const { data } = await teacherService.getAll(filters)

      commit(types.SET_TEACHERS_PAGED, data)
    } finally {
      commit(types.SET_LOADING_TEACHER, false)
    }
  },
  addSelectedTeacher: ({ commit }, teacher) => {
    commit(types.SET_SELECT_TEACHER, teacher)
  },
  setListTeacher: ({ commit }, teacherList) => {
    commit(types.SET_NEW_LIST, teacherList)
  },
  resetSelectedTeachers: ({ commit }) => {
    commit(types.SET_RESET_TEACHER)
  },
  removeSelectedTeacher: ({ commit }, student) => {
    commit(types.SET_REMOVE_TEACHER, student)
  },
  setTeacherIdUpdated: ({ commit }, teacherIdUpdated) => {
    commit(types.SET_TEACHER_UPDATED, teacherIdUpdated)
  },
  deleteTeachers: async ({ commit }, ids) => {
    await teacherService.delete(ids)
    commit(types.DELETED_TEACHERS_IDS, ids)
  },
  setTeacherIdEdit: ({ commit }, teacherIdEdit) => {
    commit(types.TEACHER_ID_EDIT, teacherIdEdit)
  },
  setTeacherLogin: ({ commit }, teacherLogin) => {
    commit(types.TEACHER_LOGIN, teacherLogin)
  },
  getGradesWithoutContracts: async ({ commit }, grades) => {
    const { data: contracts } = await teacherService.getContracts()

    const gradesWithoutContracts = grades.filter((grade) => {
      const hasContract = contracts.some((contract) => contract.gradeId === grade.gradeId)

      return !hasContract
    })

    commit(types.SET_GRADES_WITHOUT_CONTRACTS, gradesWithoutContracts)
  },
}
