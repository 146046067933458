
import router from '@/router'
import schoolService from '@/api/school/schoolService'

import { MANAGER_SELECTED } from '../manager/types'

import * as types from './types'

export default {
  getSchoolsPaged: async ({ commit }, filters) => {
    commit(types.SET_LOADING_SCHOOL, true)

    try {
      const { data } = await schoolService.getAll(filters)
      commit(types.SET_SCHOOLS_PAGED, data)
    } catch {
      router.replace('/')
    } finally {
      commit(types.SET_LOADING_SCHOOL, false)
    }
  },
  addSelectedSchool: ({ commit }, school) => {
    commit(types.SET_SELECT_SCHOOL, school)
  },
  setListSchool: ({ commit }, schoolList) => {
    commit(types.SET_NEW_LIST_SCHOOL, schoolList)
  },
  resetSelectedSchools: ({ commit }) => {
    commit(types.SET_RESET_SCHOOL)
  },
  removeSelectedSchool: ({ commit }, school) => {
    commit(types.SET_REMOVE_SCHOOL, school)
  },
  deleteSchools: async ({ commit }, ids) => {
    await schoolService.delete(ids)
    commit(types.DELETED_SCHOOLS_IDS, ids)
  },
  editSchool: async ({ commit }, editPayload) => {
    const { data } = await schoolService.update(editPayload)
    commit(types.UPDATED_SCHOOL, data)

    return data
  },
  setSchoolSelected: ({ commit }, schoolSelected) => {
    commit(MANAGER_SELECTED, schoolSelected)
  },
  resetSchoolSelected: ({ commit }) => {
    commit(types.SCHOOL_SELECTED, {})
  },
  setSchoolInfoSelected: ({ commit }, schoolInfoSelected) => {
    commit(types.SET_SCHOOL_INFO_SELECTED, schoolInfoSelected)
  },
}
