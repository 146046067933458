export default {
  loadingSchool: (state) => state.loadingSchool,
  schoolsPaged: (state) => state.schoolsPaged,
  selectedSchools: (state) => state.selectedSchools,
  deletedSchoolId: (state) => state.deletedSchoolId,
  updatedSchool: (state) => state.updatedSchool,
  deletedSchoolsIds: (state) => state.deletedSchoolsIds,
  schoolSelected: (state) => state.schoolSelected,
  schoolInfoSelected: (state) => state.schoolInfoSelected,
}
