import api from '@/api'
import { clearEmptyParams } from '@/utils'

const buildFilters = (filters) => {
  const queryString = {
    filter: filters.filter || '',
    year: filters.year || '',
    sort: filters.sort || '',
    size: filters.size || '',
    direction: filters.direction || '',
    page: filters.page || '',
  }

  const queryStrings = clearEmptyParams(Object.keys(queryString)
    .map((key) => `${key}=${queryString[key]}`).join('&'))

  const body = {
    grades: filters.grades,
    lectures: filters.lectures,
    status: filters.status,
  }

  return {
    queryStrings,
    body,
  }
}

export default {
  getLectures: () => api.get('lectures'),
  getAll: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `teachers/search?${resultFilters.queryStrings}`,
      resultFilters.body,
    )
  },
  getByAccountId: (accountId) => api.get(`teachers/${accountId}`),
  create: (body) => api.post('teachers', body),
  update: (body, accountId) => api.put(`teachers/${accountId}`, body),
  delete: (ids) => api.request({
    method: 'delete',
    url: 'teachers',
    data: ids,
  }),
  exportAccessKey: (students) => api({
    method: 'post',
    url: 'export/teacher/accessKeyPdf',
    data: students,
    responseType: 'blob',
  }),
  exportAccesKeyFiltered: (filters) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `export/teacher/accessKeyPdf/search?${resultFilters.queryStrings}`,
      resultFilters.body,
      {
        responseType: 'blob',
      },
    )
  },
  exportDefaultWorksheet: () => api.get(
    'export/teacher/defaultSpreadSheet',
    {
      responseType: 'blob',
    },
  ),
  importWorksheet: (file) => {
    const formData = new FormData()

    formData.append('content', file)

    return api.post(
      'import/teachers',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },
  getContracts: () => api.get('contracts'),
}
