export const SET_TEACHERS_PAGED = 'SET_TEACHERS_PAGED'
export const SET_LOADING_TEACHER = 'SET_LOADING_TEACHER'
export const SET_SELECT_TEACHER = 'SET_SELECT_TEACHER'
export const SET_RESET_TEACHER = 'SET_RESET_TEACHER'
export const SET_REMOVE_TEACHER = 'SET_REMOVE_TEACHER'
export const SET_NEW_LIST = 'SET_NEW_LIST'
export const DELETED_TEACHER_ID = 'DELETED_TEACHER_ID'
export const UPDATED_TEACHER = 'UPDATED_TEACHER'
export const DELETED_TEACHERS_IDS = 'DELETED_TEACHERS_IDS'
export const TEACHER_ID_EDIT = 'TEACHER_ID_EDIT'
export const SET_TEACHER_UPDATED = 'SET_TEACHER_UPDATED'
export const SET_GRADES_WITHOUT_CONTRACTS = 'SET_GRADES_WITHOUT_CONTRACTS'
export const TEACHER_LOGIN = 'TEACHER_LOGIN'
