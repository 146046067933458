import Vue from 'vue'
import alfabeto from '@sas-te/alfabeto-vue'
import mainMenu from '@sas-te/main-menu-vue'
import dayjs from 'dayjs'
import VueCollapse from 'vue2-collapse'

import 'dayjs/locale/pt-br'

import '@sas-te/alfabeto-vue/dist/alfabeto.css'

import trakingAction from '@/mixins/gtm'

import i18n from '@/i18n'

import App from '@/App'
import router from '@/router'
import store from '@/store'

import '@/plugins'

dayjs.locale('pt-br')

Vue.use(alfabeto)
Vue.use(mainMenu, {
  store,
  env: process.env.VUE_APP_MODE,
})
Vue.use(VueCollapse)

Vue.config.productionTip = false

Vue.mixin({
  methods: { trakingAction },
})

new Vue({
  name: 'Root',
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
