import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  classrooms: [],
  classroomById: {},
  loadingClassrooms: false,
  paginatedClassrooms: {},
  paginatedClassroomsStudents: {},
  paginatedClassroomsTeachers: {},
  updatedClassroom: null,
  importClassroomResult: {
    successCount: 0,
    alreadyCreatedCount: 0,
    errorList: [],
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
