import mutations from './mutations'

const state = {
  isTypeformReady: false,
}

export default {
  namespaced: true,
  state,
  mutations,
}
