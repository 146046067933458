import api from '@/api'
import urls from '@/utils/urls'

const config = { baseURL: urls.AUTH_API }

export default {
  getUser: () => api.get('v1/users/me', config),
  getServerDate: () => api.get('v1/users/me/currentDate', config),
  getReallocationLoginDateLimit: () => api.get(
    'v2/users/me/reallocation-login-date-limit',
    config,
  ),
}
