import Vue from 'vue'
import unleashPlugin from './unleash-plugin'
import urls from '@/utils/urls'

Vue.use(unleashPlugin, {
  config: {
    url: urls.UNLEASH_URL,
    clientKey: process.env.VUE_APP_UNLEASH_SECRET_CLIENT_KEY,
    appName: 'school-management-frontend',
  },
  // context will be updated in App.vue
  context: { userId: null },
})
