import api from '@/api'
import { clearEmptyParams } from '@/utils'
import urls from '@/utils/urls'

const baseURL = urls.SCHOOL_BFF

const buildFilters = (filters) => {
  let queryString
  let queryParameters
  let body = {}

  if (filters) {
    queryString = {
      filter: filters.filter,
      text: filters.text,
      description: filters.description || filters.filter,
      sort: filters.sort,
      size: filters.size,
      direction: filters.direction,
      page: filters.page || 0,
      name: filters.name,
      isDeleted: filters.isDeleted,
    }

    queryParameters = clearEmptyParams(
      Object
        .keys(queryString)
        .map(
          (key) => (typeof queryString[key] === 'undefined' ? '' : `${key}=${queryString[key]}`),
        ).filter(Boolean).join('&'),
    )

    if (queryParameters) {
      queryParameters = `?${queryParameters}`
    }

    body = {
      grades: filters.grades,
      shifts: filters.shifts,
      year: filters.year,
      classrooms: filters.classrooms,
      status: filters.status,
      classroomIds: filters.classroomIds,
    }
  }

  return {
    queryParameters,
    body,
  }
}

export default {
  find: (id) => api.get(`classrooms/${id}`),
  filter: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(`${baseURL}/classrooms/search${resultFilters.queryParameters}`, resultFilters.body)
  },
  getClassrooms: (year) => {
    const yearLength = 4
    let params

    if (year && String(year).trim().length === yearLength) {
      params = { year }
    }

    return api.get(`${baseURL}/classrooms`, { params })
  },
  getClassroomsByGradeAndShiftAndName: (gradeId, shift, name) => api
    .get(`grades/${gradeId}/classrooms`, {
      params: {
        shift,
        name,
      },
    }),
  getMigratingStudents: (classroomId) => api
    .get(`reallocate/classrooms/${classroomId}/students`),
  create: (gradeId, model) => api.post(`${baseURL}/grades/${gradeId}/classrooms`, model),
  edit: (model) => api.put(`${baseURL}/classrooms/${model.id}`, model),
  delete: (id) => api.delete(`${baseURL}/classrooms/${id}`),
  exportDefaultWorksheet: () => api.get(
    'export/classroom/defaultSpreadSheet',
    {
      responseType: 'blob',
    },
  ),
  importWorksheet: (file) => {
    const formData = new FormData()

    formData.append('content', file)

    return api.post(
      'import/classroom',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },
  getClassroomStudents: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(`${baseURL}/classrooms/students${resultFilters.queryParameters}`, resultFilters.body)
  },
  getClassroomTeachers: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(`${baseURL}/classrooms/teachers${resultFilters.queryParameters}`, resultFilters.body)
  },
}
