import api from '@/api'
import { clearEmptyParams } from '@/utils'
import urls from '@/utils/urls'

const baseURL = urls.SCHOOL_BFF

const buildFilters = (filters) => {
  let queryString
  let queryParameters
  let body = {}
  const DEFAULT_PAGE_SIZE = 50

  if (filters) {
    queryString = {
      sort: filters.sort,
      size: filters.size || DEFAULT_PAGE_SIZE,
      direction: filters.direction?.toUpperCase(),
      page: filters.page || 0,
      isItinerary: filters.isItinerary,
      isDeleted: filters.isDeleted,
      name: filters.name,
      description: filters.description || filters.name,
      accessKey: filters.name,
      gradeIds: filters.gradeIds,
      classroomIds: filters.classroomIds,
      LectureIds: filters.lectureIds, // TEMP pendente correção no back
    }

    queryParameters = clearEmptyParams(
      Object
        .keys(queryString)
        .map(
          (key) => (typeof queryString[key] === 'undefined' ? '' : `${key}=${queryString[key]}`),
        ).filter(Boolean).join('&'),
    )

    if (queryParameters) {
      queryParameters = `?${queryParameters}`
    }

    body = {}
  }

  return {
    queryParameters,
    body,
  }
}

export default {
  create: (model) => api.post(`${baseURL}/school-groups`, model),
  delete: (id) => api.delete(`${baseURL}/school-groups/${id}`),
  edit: (id, model) => api.put(`${baseURL}/school-groups/${id}`, model),
  find: (id) => api.get(`${baseURL}/school-groups/${id}`),
  filter: (filters) => {
    const resultFilters = buildFilters(filters)

    return api.get(
      `${baseURL}/school-groups${resultFilters.queryParameters}`,
    )
  },
  getKnowledgeAreas: () => api.get(`${baseURL}/knowledge-areas/`),
  getStudentGroups: (id) => api.get(`${baseURL}/school-groups/account/${id}`),
  getStudents: (id, filters) => {
    const resultFilters = buildFilters(filters)

    return api.get(
      `${baseURL}/school-groups/${id}/students${resultFilters.queryParameters}`,
    )
  },
  getCandidateStudents: (id, filters) => {
    const resultFilters = buildFilters(filters)

    return api
      .get(`${baseURL}/school-groups/${id}/candidate-students${resultFilters.queryParameters}`)
  },
  getTeachers: (id, filters) => {
    const resultFilters = buildFilters(filters)

    return api.get(`${baseURL}/school-groups/${id}/teachers${resultFilters.queryParameters}`)
  },
  getCandidateTeachers: (id, filters) => {
    const resultFilters = buildFilters(filters)

    return api
      .get(`${baseURL}/school-groups/${id}/candidate-teachers${resultFilters.queryParameters}`)
  },
  getClassrooms: (id) => api.get(`${baseURL}/grades/${id}/classrooms`),
  postStudents: (id, list) => api.post(
    `${baseURL}/school-groups/${id}/students`,
    list,
  ),
  deleteParticipants: (id, list) => api.delete(
    `${baseURL}/school-groups/${id}/members`,
    { data: list },
  ),
  postTeachers: (id, list) => api.post(
    `${baseURL}/school-groups/${id}/teachers`,
    list,
  ),
}
