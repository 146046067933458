import actions from './actions'
import mutations from './mutations'

const state = {
  groups: [],
  grades: [],
  knowledgeAreas: [],
  groupStudents: [],
  candidateStudents: [],
  selectedParticipants: [],
  selectedCandidates: [],
  paginatedGroups: {},
  groupById: {},
  groupTeachers: [],
  candidateTeachers: [],
  selectedTeachers: [],
  showTourGroupAfterCreate: false,
}

export default {
  state,
  mutations,
  actions,
}
