import * as types from './types'

export default {
  [types.SET_EXCEPTIONS_PAGED](state, exceptionsPaged) {
    state.exceptionsPaged = exceptionsPaged
  },
  [types.SET_LOADING_EXCEPTIONS](state, loadingExceptions) {
    state.loadingExceptions = loadingExceptions
  },
  [types.SET_EXCEPTION_GRADES_FILTER](state, grades) {
    state.exceptionGradesFilter = grades
  },
  [types.SET_EXCEPTION_YEAR_FILTER](state, years) {
    state.exceptionYearsFilter = years
  },
}
