import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  loadingManager: false,
  managersPaged: {},
  selectedManagers: [],
  deletedManagerId: null,
  updatedManager: null,
  managementProfiles: [],
  managerSelected: {},
}

export default {
  state,
  getters,
  mutations,
  actions,
}
