import studentService from '@/api/student/studentService'

import * as types from './types'

export default {
  getStudents: async ({ commit }, filters) => {
    commit(types.SET_LOADING_STUDENTS, true)
    const { data } = await studentService.getAll(filters)
    commit(types.SET_STUDENTS_PAGED, data)
    commit(types.SET_LOADING_STUDENTS, false)
  },
  addSelectedStudent: ({ commit }, student) => {
    commit(types.SET_SELECT_STUDENT, student)
  },
  setListStudent: ({ commit }, studentList) => {
    commit(types.SET_NEW_LIST, studentList)
  },
  resetSelectedStudents: ({ commit }) => {
    commit(types.SET_RESET_STUDENT)
  },
  removeSelectedStudent: ({ commit }, student) => {
    commit(types.SET_REMOVE_STUDENT, student)
  },
  updateStudent: async ({ commit }, { payload, typeUpdate }) => {
    const response = await studentService.update(payload)

    commit(types.SET_STUDENTS_UPDATED, [payload.id])
    commit(
      types.SET_TYPE_UPDATE,
      [typeUpdate || 'studentLastModifiedDate'],
    )

    return response
  },
  updateStudentImportResult: ({ commit }, result) => {
    commit(types.SET_UPDATE_IMPORT_CONFLICT, result)
  },
  updateSpecificStudentImportResult: ({ commit }, conflictToUpdate) => {
    commit(types.SET_SPECIFIC_IMPORT_CONFLICT, conflictToUpdate)
  },
  deleteImportStudentConflict: ({ commit }, conflictToDelete) => {
    commit(types.DELETE_IMPORT_STUDENT_CONFLICT, conflictToDelete)
  },
  resolveImportStudentConflict: ({ commit }, index) => {
    commit(types.SET_RESOLVE_IMPORT_CONFLICT, index)
  },
  resetImportStudentConflict: ({ commit }) => {
    commit(types.SET_RESET_IMPORT_CONFLICT)
  },
  bulkStudentUpdate: async ({ commit }, params) => {
    await studentService.bulkStudentUpdate(params.payload)
    commit(types.SET_STUDENTS_UPDATED, params.payload.studentsId)
    commit(types.SET_TYPE_UPDATE, [params.typeUpdate])
  },
  deleteStudent: async ({ commit }, studentId) => {
    const response = await studentService.delete(studentId)
    commit(types.DELETED_STUDENT_ID, studentId)

    return response
  },
}
