import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  loadingSchool: false,
  schoolsPaged: {},
  selectedSchools: [],
  deletedSchoolId: null,
  updatedSchool: null,
  schoolSelected: {},
  schoolInfoSelected: {},
}

export default {
  state,
  getters,
  mutations,
  actions,
}
