import api from '@/api'
import { clearEmptyParams } from '@/utils'
import urls from '@/utils/urls'

const baseUrl = urls.CONTRACT_EXCEPTION_URL

const buildFilters = (filters) => {
  const queryString = {
    page: filters.page || '',
    filter: filters.filter || '',
  }

  const queryStrings = clearEmptyParams(Object.keys(queryString)
    .map((key) => `${key}=${queryString[key]}`).join('&'))

  const body = {
    grades: filters.grades || [],
    years: filters.years || [],
  }

  return {
    queryStrings,
    body,
  }
}

export default {
  getContractsExceptions: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `${baseUrl}/contractsException/search?${resultFilters.queryStrings}`,
      resultFilters.body,
    )
  },
  getContractByCode: (schoolCode) => api.get(`schools/code/${schoolCode}`),
  getBySchoolCodeAndGradeId: (schoolCode, gradeId) => api.get(
    `${baseUrl}/contractsException/school/${schoolCode}/grade/${gradeId}`,
  ),
  find: (contractId) => api.get(
    `${baseUrl}/contractsException/${contractId}`,
  ),
  create: (body) => api.post(`${baseUrl}/contractsException`, body),
  update: (params) => api.put(
    `${baseUrl}/contractsException/${params.id}`,
    params.body,
  ),
  delete: (id) => api.delete(`${baseUrl}/contractsException/${id}`),
  getGradesFilter: () => api.get(`${baseUrl}/filters/grades`),
  getYearsFilter: () => api.get(`${baseUrl}/filters/year`),
}
