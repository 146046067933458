import Vue from 'vue'
import { UnleashClient } from 'unleash-proxy-client'
import router from '@/router'

const toggleliberaGrupos = () => {
  Vue.unleash.on('update', () => {
    const isProduction = process.env.VUE_APP_MODE === 'production'

    if (!Vue.unleash.isEnabled(isProduction ? 'libera-grupos-prod' : 'libera-grupos-dev')) {
      router.push('/')
    }
  })
}

export default {
  install(_Vue, options) {
    const unleash = new UnleashClient(options.config)

    unleash.updateContext(options.context)

    // unleash will be started in App.vue to prevent errors
    // unleash.start()

    /* eslint-disable no-param-reassign */
    _Vue.unleash = unleash

    _Vue.unleash.blockRoute = {
      liberaGrupos: toggleliberaGrupos,
    }
  },
}
