import * as types from './types'

export default {
  [types.SET_TEACHERS_PAGED](state, teachersPaged) {
    state.teachersPaged = teachersPaged
  },
  [types.SET_LOADING_TEACHER](state, loadingTeacher) {
    state.loadingTeacher = loadingTeacher
  },
  [types.SET_SELECT_TEACHER](state, selectedTeachers) {
    state.selectedTeachers.push(selectedTeachers)
  },
  [types.SET_RESET_TEACHER](state) {
    state.selectedTeachers = []
  },
  [types.SET_NEW_LIST](state, newList) {
    state.selectedTeachers = newList
  },
  [types.SET_REMOVE_TEACHER](state, selectedTeacher) {
    const idToRemove = state.selectedTeachers
      .findIndex((teacher) => teacher.accountId === selectedTeacher.accountId)
    state.selectedTeachers.splice(idToRemove, 1)
  },
  [types.DELETED_TEACHER_ID](state, deletedTeacherId) {
    state.deletedTeacherId = deletedTeacherId
  },
  [types.SET_TEACHER_UPDATED](state, teacherIdUpdated) {
    state.teacherIdUpdated = teacherIdUpdated
  },
  [types.DELETED_TEACHERS_IDS](state, deletedTeachersIds) {
    state.deletedTeachersIds = deletedTeachersIds
  },
  [types.TEACHER_ID_EDIT](state, teacherIdEdit) {
    state.teacherIdEdit = teacherIdEdit
  },
  [types.TEACHER_LOGIN](state, teacherLogin) {
    state.teacherLogin = teacherLogin
  },
  [types.SET_GRADES_WITHOUT_CONTRACTS](state, gradesWithoutContracts) {
    state.gradesWithoutContracts = gradesWithoutContracts
  },
}
