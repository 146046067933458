<template>
  <div class="loading">
    <Spinner /> Carregando
  </div>
</template>

<script>
import { Spinner } from '@sas-te/alfabeto-vue'

export default {
  name: 'LoadingView',
  components: { Spinner },
  page: {
    title: 'Carregando página...',
    meta: [
      {
        name: 'description',
        content: 'Carregando página...',
      },
    ],
  },
}
</script>
