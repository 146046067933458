import {
  GRADES,
  GROUPS,
  PAGINATED_GROUPS,
  CANDIDATE_STUDENTS,
  GROUP_STUDENTS,
  KNOWLEDGE_AREAS,
  SELECTED_STUDENTS,
  SELECTED_CANDIDATES,
  GROUP_TEACHERS,
  CANDIDATE_TEACHERS,
  SELECTED_TEACHERS,
  GROUP_BY_ID,
  SHOW_TOUR_GROUP_AFTER_CREATE,
} from './types'

export default {
  [KNOWLEDGE_AREAS](state, knowledgeAreas) {
    state.knowledgeAreas = knowledgeAreas
  },
  [GROUPS](state, groups) {
    state.groups = groups
  },
  [GRADES](state, grades) {
    state.grades = grades
  },
  [PAGINATED_GROUPS](state, paginatedGroups) {
    state.paginatedGroups = paginatedGroups
  },
  [GROUP_STUDENTS](state, groupStudents) {
    state.groupStudents = groupStudents
  },
  [SELECTED_STUDENTS](state, selected) {
    state.selectedParticipants = selected
  },
  [CANDIDATE_STUDENTS](state, candidateStudents) {
    state.candidateStudents = candidateStudents
  },
  [SELECTED_CANDIDATES](state, selected) {
    state.selectedCandidates = selected
  },
  [GROUP_TEACHERS](state, groupTeachers) {
    state.groupTeachers = groupTeachers
  },
  [CANDIDATE_TEACHERS](state, candidateTeachers) {
    state.candidateTeachers = candidateTeachers
  },
  [SELECTED_TEACHERS](state, selected) {
    state.selectedTeachers = selected
  },
  [GROUP_BY_ID](state, groupById) {
    state.groupById = groupById
  },
  [SHOW_TOUR_GROUP_AFTER_CREATE](state, isShowTourGroupAfterCreate) {
    state.showTourGroupAfterCreate = isShowTourGroupAfterCreate
  },
}
