import contractExceptionService from '@/api/contractException/contractExceptionService'

import * as types from './types'

export default {
  getExceptionsPaged: async ({ commit }, filters) => {
    commit(types.SET_LOADING_EXCEPTIONS, true)

    try {
      const { data } = await contractExceptionService
        .getContractsExceptions(filters)

      commit(types.SET_EXCEPTIONS_PAGED, data)
    } finally {
      commit(types.SET_LOADING_EXCEPTIONS, false)
    }
  },
  getExceptionsGrades: async ({ commit }) => {
    const { data } = await contractExceptionService.getGradesFilter()
    commit(types.SET_EXCEPTION_GRADES_FILTER, data)
  },
  getExceptionsYears: async ({ commit }) => {
    const { data } = await contractExceptionService.getYearsFilter()
    commit(types.SET_EXCEPTION_YEAR_FILTER, data)
  },
}
