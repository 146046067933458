import * as types from './types'

export default {
  [types.SET_CONTRACTED_KITS](state, contractedKitsAmount) {
    state.contractedKitsAmount = contractedKitsAmount
  },
  [types.SET_REMAINING_KITS](state, remainingKits) {
    state.remainingKits = remainingKits
  },
}
