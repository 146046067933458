import * as types from './types'

export default {
  [types.SET_SCHOOLS_PAGED](state, schoolsPaged) {
    state.schoolsPaged = schoolsPaged
  },
  [types.SET_LOADING_SCHOOL](state, loadingSchool) {
    state.loadingSchool = loadingSchool
  },
  [types.SET_SELECT_SCHOOL](state, selectedSchools) {
    state.selectedSchools.push(selectedSchools)
  },
  [types.SET_RESET_SCHOOL](state) {
    state.selectedSchools = []
  },
  [types.SET_NEW_LIST_SCHOOL](state, newList) {
    state.selectedSchools = newList
  },
  [types.SET_REMOVE_SCHOOL](state, selectedSchool) {
    const idToRemove = state.selectedSchools
      .findIndex((school) => school.accountId === selectedSchool.accountId)
    state.selectedSchools.splice(idToRemove, 1)
  },
  [types.DELETED_SCHOOL_ID](state, deletedSchoolId) {
    state.deletedSchoolId = deletedSchoolId
  },
  [types.UPDATED_SCHOOL](state, updatedSchool) {
    state.updatedSchool = updatedSchool
  },
  [types.DELETED_SCHOOLS_IDS](state, deletedSchoolsIds) {
    state.deletedSchoolsIds = deletedSchoolsIds
  },
  [types.SCHOOL_SELECTED](state, schoolSelected) {
    state.schoolSelected = schoolSelected
  },
  [types.SET_SCHOOL_INFO_SELECTED](state, schoolInfoSelected) {
    state.schoolInfoSelected = schoolInfoSelected
  },
}
