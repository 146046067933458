import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  loadingTeacher: false,
  teachersPaged: {},
  selectedTeachers: [],
  deletedTeacherId: null,
  teacherIdUpdated: null,
  teacherIdEdit: null,
  teacherLogin: '',
  gradesWithoutContracts: [],
}

export default {
  state,
  getters,
  mutations,
  actions,
}
