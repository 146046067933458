import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  gradesUser: [],
  lectures: null,
}

export default {
  state,
  getters,
  mutations,
  actions,
}
