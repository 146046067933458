import classroomService from '@/api/classroom/classroomService'

import * as types from './types'

export default {
  getClassrooms: async ({ commit }, year) => {
    const { data } = await classroomService.getClassrooms(year)

    commit(types.CLASSROOMS, data)
  },
  getClassroomById: async ({ commit }, idParam) => {
    const { data } = await classroomService.find(idParam)

    commit(types.CLASSROOM_BY_ID, data)
  },
  getPaginatedClassroomStudents: async ({ commit }, filters) => {
    const { data } = await classroomService.getClassroomStudents(filters)

    commit(types.SET_PAGINATED_CLASSROOMS_STUDENTS, data)
  },
  getPaginatedClassroomTeachers: async ({ commit }, filters) => {
    const { data } = await classroomService.getClassroomTeachers(filters)

    commit(types.SET_PAGINATED_CLASSROOMS_TEACHERS, data)
  },
  getPaginatedClassrooms: async ({ commit }, filters) => {
    commit(types.SET_LOADING_CLASSROOMS, true)

    const { data } = await classroomService.filter(filters)

    commit(types.SET_PAGINATED_CLASSROOMS, data)
    commit(types.SET_LOADING_CLASSROOMS, false)
  },
  searchClassRoom: async ({ commit }, gradeId, shift, name) => {
    try {
      const { data } = await classroomService.getClassroomsByGradeAndShiftAndName(
        gradeId,
        shift,
        name,
      )

      commit(types.CLASSROOMS, data)
    } catch (error) {
      this.$toasted.global.error({
        message: 'Turma não encontrada',
        title: error,
      })
    }
  },
  updateClassroom: async ({ commit }, classroom) => {
    const { data } = await classroomService.edit(classroom)

    commit(types.SET_UPDATED_CLASSROOM, data)
  },
  updateClassroomImportResult: ({ commit }, result) => {
    commit(types.SET_UPDATE_IMPORT_CONFLICT, result)
  },
  updateSpecificClassroomImportResult: ({ commit }, conflictToUpdate) => {
    commit(types.SET_SPECIFIC_IMPORT_CONFLICT, conflictToUpdate)
  },
  deleteImportClassroomConflict: ({ commit }, conflictToDelete) => {
    commit(types.DELETE_IMPORT_CLASSROOM_CONFLICT, conflictToDelete)
  },
  resolveImportClassroomConflict: ({ commit }, index) => {
    commit(types.SET_RESOLVE_IMPORT_CONFLICT, index)
  },
  resetImportClassroomConflict: ({ commit }) => {
    commit(types.SET_RESET_IMPORT_CONFLICT)
  },
}
