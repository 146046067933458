export const SET_MANAGERS_PAGED = 'SET_MANAGERS_PAGED'
export const SET_LOADING_MANAGER = 'SET_LOADING_MANAGER'
export const SET_SELECT_MANAGER = 'SET_SELECT_MANAGER'
export const SET_RESET_MANAGER = 'SET_RESET_MANAGER'
export const SET_REMOVE_MANAGER = 'SET_REMOVE_MANAGER'
export const SET_NEW_LIST_MANAGER = 'SET_NEW_LIST_MANAGER'
export const DELETED_MANAGER_ID = 'DELETED_MANAGER_ID'
export const UPDATED_MANAGER = 'UPDATED_MANAGER'
export const DELETED_MANAGERS_IDS = 'DELETED_MANAGERS_IDS'
export const MANAGEMENT_PROFILES = 'MANAGEMENT_PROFILES'
export const MANAGER_SELECTED = 'MANAGER_SELECTED'
