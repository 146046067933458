import axios from 'axios'
import { stringify } from 'qs'

import {
  refreshAuthTokens,
  hasRefreshToken,
  getAuthToken,
  logout,
} from '@/utils/auth'
import httpCodes from '@/enums/httpCodes'
import urls from '@/utils/urls'

export const addAuthorizationHeader = (config) => {
  const requestConfig = { ...config }

  requestConfig.headers.Authorization = getAuthToken()

  return requestConfig
}

async function handleRequestInterceptorError(error) {
  const handleRedirection = (status) => {
    const errorsWithPages = [
      httpCodes.serviceUnavailable,
      // those errors shouldn't exist as pages
      httpCodes.internalServerError,
      httpCodes.forbidden,
    ]

    if (status === httpCodes.unauthorized) {
      logout({ redirectBack: true })
    } else if (errorsWithPages.includes(status)) {
      window.location.assign(`${urls.PORTAL_URL}/error/${status}`)
    }
  }

  if (error.response) {
    if (error.response.status === httpCodes.unauthorized && hasRefreshToken()) {
      try {
        await refreshAuthTokens()

        return axios(addAuthorizationHeader(error.config))
      } catch (newError) {
        handleRedirection(newError.response.status)
      }
    } else {
      handleRedirection(error.response.status)
    }
  }

  return Promise.reject(error)
}

const api = axios.create({
  baseURL: urls.SCHOOL_API,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
})

api.interceptors.request.use(addAuthorizationHeader)
api.interceptors.response.use((response) => response, handleRequestInterceptorError)

axios.defaults.baseURL = urls.SCHOOL_API

export default api
