import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  loadingStudents: false,
  studentsPaged: {},
  selectedStudents: [],
  deletedStudentId: null,
  importStudentResult: {
    successCount: 0,
    alreadyCreatedCount: 0,
    errorList: [],
  },
  studentsUpdated: [],
  typeUpdate: null,
  showStudentImport: false,
}

export default {
  state,
  getters,
  mutations,
  actions,
}
