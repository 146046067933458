import contractsService from '@/api/contracts/contractsService'

import * as types from './types'

export default {
  getContractedKits: async ({ commit }) => {
    const { data } = await contractsService.getContractedKitsAmount()
    commit(types.SET_CONTRACTED_KITS, data.contractedKitsAmount)
  },
  getRemainingKits: async ({ commit }, gradeId) => {
    const { data } = await contractsService.getRemainingKits(gradeId)
    commit(types.SET_REMAINING_KITS, data.remainingKits)
  },
}
