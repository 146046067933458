import urls from '@/utils/urls'
import {
  INSERT_ROUTE,
  REMOVE_ROUTE,
  CLEAN_ROUTE,
} from './types'

export default {
  [INSERT_ROUTE](state, route) {
    state.routes.push(route)
  },
  [REMOVE_ROUTE](state, indexRemoveRoute) {
    state.routes.splice(indexRemoveRoute, 1)
  },
  [CLEAN_ROUTE](state) {
    state.routes = [
      {
        name: 'Página inicial',
        href: urls.NEW_HOME_URL,
      },
      {
        name: 'Cadastros e kits',
        to: { name: 'home' },
      },
    ]
  },

}
