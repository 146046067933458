import api from '@/api'
import { clearEmptyParams } from '@/utils'

const buildFilters = (filters) => {
  let queryString
  let queryParameters
  let body = {}

  if (filters) {
    queryString = {
      filter: filters.filter || '',
      year: filters.year || '',
      sort: filters.sort || '',
      size: filters.size || '',
      direction: filters.direction || '',
      page: filters.page || '',
    }

    queryParameters = clearEmptyParams(Object.keys(queryString)
      .map((key) => `${key}=${queryString[key]}`).join('&'))

    if (queryParameters) {
      queryParameters = `?${queryParameters}`
    }

    body = {
      profiles: filters.profiles,
      status: filters.status,
    }
  }

  return {
    queryParameters: queryParameters || '',
    body,
  }
}

export default {
  getAll: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `managers/search${resultFilters.queryParameters}`,
      resultFilters.body,
    )
  },
  delete: (ids) => api.request({
    method: 'delete',
    url: 'managers',
    data: ids,
  }),
  exportAccessKey: (students) => api({
    method: 'post',
    url: 'export/manager/accessKeyPdf',
    data: students,
    responseType: 'blob',
  }),
  exportAccesKeyFiltered: (filters) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `export/manager/accessKeyPdf/search${resultFilters.queryParameters}`,
      resultFilters.body,
      {
        responseType: 'blob',
      },
    )
  },
  getAccesKeyCounts: () => api.get('accessKeys/count'),
  getManagementProfiles: () => api.get('managers/managementProfiles'),
  createBulk: (managers) => api.post('managers/bulk', managers),
  update: (manager) => api.put(`managers/${manager.accountId}`, manager),
  exportDefaultWorksheet: () => api.get(
    'export/manager/defaultSpreadSheet',
    {
      responseType: 'blob',
    },
  ),
  importWorksheet: (file) => {
    const formData = new FormData()

    formData.append('content', file)

    return api.post(
      'import/managers',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },
}
