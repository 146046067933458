export const SET_SCHOOLS_PAGED = 'SET_SCHOOLS_PAGED'
export const SET_LOADING_SCHOOL = 'SET_LOADING_SCHOOL'
export const SET_SELECT_SCHOOL = 'SET_SELECT_SCHOOL'
export const SET_RESET_SCHOOL = 'SET_RESET_SCHOOL'
export const SET_REMOVE_SCHOOL = 'SET_REMOVE_SCHOOL'
export const SET_NEW_LIST_SCHOOL = 'SET_NEW_LIST_SCHOOL'
export const SET_SCHOOL_INFO_SELECTED = 'SET_SCHOOL_INFO_SELECTED'
export const DELETED_SCHOOL_ID = 'DELETED_SCHOOL_ID'
export const UPDATED_SCHOOL = 'UPDATED_SCHOOL'
export const DELETED_SCHOOLS_IDS = 'DELETED_SCHOOLS_IDS'
export const SCHOOL_SELECTED = 'SCHOOL_SELECTED'
