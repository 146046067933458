export const GROUPS = '@groups/GROUPS'
export const PAGINATED_GROUPS = '@groups/PAGINATED_GROUPS'
export const KNOWLEDGE_AREAS = '@groups/KNOWLEDGE_AREAS'
export const LECTURES = 'LECTURES'
export const GROUP_STUDENTS = '@groups/PAGINATED_STUDENTS'
export const SELECTED_STUDENTS = '@groups/SELECTED_STUDENTS'
export const SELECTED_CANDIDATES = '@groups/SELECTED_CANDIDATES'
export const CANDIDATE_STUDENTS = '@groups/CANDIDATE_STUDENTS'
export const GROUP_TEACHERS = '@groups/GROUP_TEACHERS'
export const CANDIDATE_TEACHERS = '@groups/CANDIDATE_TEACHERS'
export const SELECTED_TEACHERS = '@groups/SELECTED_TEACHERS'
export const GRADES = '@groups/GRADES'
export const GROUP_BY_ID = '@groups/GROUP_BY_ID'
export const SHOW_TOUR_GROUP_AFTER_CREATE = '@groups/SHOW_TOUR_AFTER_CREATE'
