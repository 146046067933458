export const CLASSROOMS = 'CLASSROOMS'
export const SET_PAGINATED_CLASSROOMS = 'SET_PAGINATED_CLASSROOMS'
export const SET_LOADING_CLASSROOMS = 'SET_LOADING_CLASSROOMS'
export const SET_REMOVE_CLASSROOM = 'SET_REMOVE_CLASSROOM'
export const SET_UPDATED_CLASSROOM = 'SET_UPDATED_CLASSROOM'
export const SET_UPDATE_IMPORT_CONFLICT = 'SET_UPDATE_IMPORT_CONFLICT'
export const DELETE_IMPORT_CLASSROOM_CONFLICT = 'DELETE_IMPORT_CLASSROOM_CONFLICT'
export const SET_SPECIFIC_IMPORT_CONFLICT = 'SET_SPECIFIC_IMPORT_CONFLICT'
export const SET_RESOLVE_IMPORT_CONFLICT = 'SET_RESOLVE_IMPORT_CONFLICT'
export const SET_RESET_IMPORT_CONFLICT = 'SET_RESET_IMPORT_CONFLICT'
export const CLASSROOM_BY_ID = 'CLASSROOM_BY_ID'
export const SET_PAGINATED_CLASSROOMS_STUDENTS = 'SET_PAGINATED_CLASSROOMS_STUDENTS'
export const SET_PAGINATED_CLASSROOMS_TEACHERS = 'SET_PAGINATED_CLASSROOMS_TEACHERS'
