
import {
  INSERT_ROUTE,
  REMOVE_ROUTE,
  CLEAN_ROUTE,
} from './types'

export default {
  setNewRouterBreadcrumb: ({ commit }, route) => {
    commit(INSERT_ROUTE, route)
  },
  removeRouterBreadcrumb: ({ commit }, indexRemoveRoute) => {
    commit(REMOVE_ROUTE, indexRemoveRoute)
  },
  resetInitialRoutesBreadcrumb: ({ commit }) => {
    commit(CLEAN_ROUTE)
  },
}
