import { clearEmptyParams, removeEmptyKeysValues } from '@/utils'
import api from '@/api'

const buildFilters = (filters) => {
  const queryString = {
    filter: filters.filter || '',
    year: filters.year || '',
    sort: filters.sort || '',
    size: filters.size || '',
    direction: filters.direction || '',
    page: filters.page || '',
  }

  const queryStrings = clearEmptyParams(Object.keys(queryString)
    .map((key) => `${key}=${queryString[key]}`).join('&'))

  const body = {
    types: filters.profiles,
    grades: filters.grades,
  }

  return {
    queryStrings,
    body,
  }
}

export default {
  getAll: (filters = {}) => {
    const resultFilters = buildFilters(filters)

    return api.post(
      `admin/schools/search?${resultFilters.queryStrings}`,
      resultFilters.body,
    )
  },
  validateSchoolCode: (schoolCode) => api.get(
    `admin/schools/code/${schoolCode}`,
  ),
  validateUser: (login) => api.get(`users/login/${login}`),
  getGradesBySchoolId: (schoolId) => api.get(
    `schools/${schoolId}/contracts`,
  ),
  create: (school) => api.post('admin/schools', school),
  migrate: (schoolCodeOrigin, schoolCodeDestination) => api.post(
    'admin/schools/migrate', {
      schoolCodeOrigin,
      schoolCodeDestination,
    },
  ),
  adminGetSchool: (schoolCode) => api.get(`admin/schools/${schoolCode}`),
  getSchool: ({ code, includeInactive }) => api.get(`schools/code/${code}`, {
    params: { includeInactive },
  }),
  updateSchool: ({ accountId, data }) => api.put(
    `admin/schools/${accountId}`,
    removeEmptyKeysValues(data),
  ),
  checkEmailUsage: (email) => api.get(
    `admin/schools/users/byEmail/${email}/accounts`,
  ),
  checkLoginUsage: (login) => api.get(`users/login/${login}`),
  editSchoolEmail: ({
    email,
    accountId,
    mergeOnConflict = false,
  }) => api.put(
    `admin/schools/account/${accountId}/user/email`,
    {
      email: email.toLowerCase(),
      mergeOnConflict,
    },
  ),
  editSchoolLogin: ({
    login,
    accountId,
    mergeOnConflict = false,
  }) => api.put(
    `admin/schools/account/${accountId}/user/login`,
    {
      login: login.toLowerCase(),
      mergeOnConflict,
    },
  ),
}
