import urls from '@/utils/urls'
import actions from './actions'
import mutations from './mutations'

const state = {
  routes: [
    {
      name: 'Página inicial',
      href: urls.NEW_HOME_URL,
    },
    {
      name: 'Cadastros e kits',
      to: { name: 'home' },
    },
  ],
}

export default {
  state,
  mutations,
  actions,
}
