import * as types from './types'

export default {
  [types.GRADES_USER](state, gradesUser) {
    state.gradesUser = gradesUser
  },
  [types.LECTURES](state, lectures) {
    state.lectures = lectures
  },
}
