import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  loadingExceptions: false,
  exceptionsPaged: {},
  exceptionGradesFilter: [],
  exceptionYearsFilter: [],
}

export default {
  state,
  getters,
  mutations,
  actions,
}
