import * as types from './types'

export default {
  [types.SET_MANAGERS_PAGED](state, managersPaged) {
    state.managersPaged = managersPaged
  },
  [types.SET_LOADING_MANAGER](state, loadingManager) {
    state.loadingManager = loadingManager
  },
  [types.SET_SELECT_MANAGER](state, selectedManagers) {
    state.selectedManagers.push(selectedManagers)
  },
  [types.SET_RESET_MANAGER](state) {
    state.selectedManagers = []
  },
  [types.SET_NEW_LIST_MANAGER](state, newList) {
    state.selectedManagers = newList
  },
  [types.SET_REMOVE_MANAGER](state, selectedManager) {
    const idToRemove = state.selectedManagers
      .findIndex((manager) => manager.accountId === selectedManager.accountId)
    state.selectedManagers.splice(idToRemove, 1)
  },
  [types.DELETED_MANAGER_ID](state, deletedManagerId) {
    state.deletedManagerId = deletedManagerId
  },
  [types.UPDATED_MANAGER](state, updatedManager) {
    state.updatedManager = updatedManager
  },
  [types.DELETED_MANAGERS_IDS](state, deletedManagersIds) {
    state.deletedManagersIds = deletedManagersIds
  },
  [types.MANAGEMENT_PROFILES](state, managementProfiles) {
    state.managementProfiles = managementProfiles
  },
  [types.MANAGER_SELECTED](state, managerSelected) {
    state.managerSelected = managerSelected
  },
}
