import managerService from '@/api/manager/managerService'

import * as types from './types'

export default {
  getManagersPaged: async ({ commit }, filters) => {
    commit(types.SET_LOADING_MANAGER, true)
    const { data } = await managerService.getAll(filters)
    commit(types.SET_MANAGERS_PAGED, data)
    commit(types.SET_LOADING_MANAGER, false)
  },
  addSelectedManager: ({ commit }, manager) => {
    commit(types.SET_SELECT_MANAGER, manager)
  },
  setListManager: ({ commit }, managerList) => {
    commit(types.SET_NEW_LIST_MANAGER, managerList)
  },
  resetSelectedManagers: ({ commit }) => {
    commit(types.SET_RESET_MANAGER)
  },
  removeSelectedManager: ({ commit }, student) => {
    commit(types.SET_REMOVE_MANAGER, student)
  },
  deleteManagers: async ({ commit }, ids) => {
    await managerService.delete(ids)
    commit(types.DELETED_MANAGERS_IDS, ids)
  },
  getManagementProfiles: async ({ commit }) => {
    const { data } = await managerService.getManagementProfiles()
    commit(types.MANAGEMENT_PROFILES, data)
  },
  editManager: async ({ commit }, editPayload) => {
    const { data } = await managerService.update(editPayload)
    commit(types.UPDATED_MANAGER, data)

    return data
  },
  setMangerSelected: ({ commit }, managerSelected) => {
    commit(types.MANAGER_SELECTED, managerSelected)
  },
  resetMangerSelected: ({ commit }) => {
    commit(types.MANAGER_SELECTED, {})
  },
}
