import groupsService from '@/api/groups/groupsService'
import gradesService from '@/api/grades/gradesService'
import contentProgramService from '@/api/contentProgram/contentProgramService'

import {
  GRADES,
  GROUPS,
  PAGINATED_GROUPS,
  CANDIDATE_STUDENTS,
  GROUP_STUDENTS,
  SELECTED_STUDENTS,
  GROUP_TEACHERS,
  CANDIDATE_TEACHERS,
  SELECTED_CANDIDATES,
  KNOWLEDGE_AREAS,
  LECTURES,
  GROUP_BY_ID,
  SHOW_TOUR_GROUP_AFTER_CREATE,
} from './types'

export default {
  getFormatedGrades: async ({ commit }) => {
    const { data } = await gradesService.getGrades()

    if (data) {
      const LAST_FUNDAMENTAL_LEVEL = 12
      const FIRST_FUNDAMENTAL_LEVEL = 3

      const grades = data.map(
        (model) => {
          let group = 'Ensino Infantil'
          if (
            model.order > FIRST_FUNDAMENTAL_LEVEL
            && model.order <= LAST_FUNDAMENTAL_LEVEL
          ) {
            group = 'Ensino Fundamental'
          } else if (model.order > LAST_FUNDAMENTAL_LEVEL) {
            group = 'Ensino Médio'
          }

          return {
            value: model.id,
            text: model.name,
            order: model.order,
            group,
          }
        },
      ).sort((a, b) => (a.order > b.order ? 1 : -1))

      commit(GRADES, grades)

      return grades
    }

    return false
  },
  getGrades: async ({ commit }) => {
    const { data } = await gradesService.getGrades()

    commit(GRADES, data)
  },
  getKnowledgeAreas: async ({ commit }) => {
    const { data } = await groupsService.getKnowledgeAreas()

    commit(KNOWLEDGE_AREAS, data)
  },
  getLecturesByArea: async ({ commit }, areaId) => {
    const { data: lectures } = await contentProgramService.getLecturesByArea(areaId)

    lectures.sort()
    commit(LECTURES, lectures)
  },
  getGroupStudents: async ({ commit }, { id, filters }) => {
    const { data } = await groupsService.getStudents(id, filters)

    commit(GROUP_STUDENTS, data)
  },
  getCandidateStudents: async ({ commit }, { id, filters }) => {
    const { data } = await groupsService.getCandidateStudents(id, filters)

    commit(CANDIDATE_STUDENTS, data)
  },
  getGroupTeachers: async ({ commit }, { id, filters }) => {
    const { data } = await groupsService.getTeachers(id, filters)

    commit(GROUP_TEACHERS, data)
  },
  getCandidateTeachers: async ({ commit }, { id, filters }) => {
    const { data } = await groupsService.getCandidateTeachers(id, filters)

    commit(CANDIDATE_TEACHERS, data)
  },
  getGroups: async ({ commit }, year) => {
    const { data } = await groupsService.getGroups(year)

    commit(GROUPS, data)
  },
  getPaginatedGroups: async ({ commit }, filters) => {
    const { data } = await groupsService.filter(filters)

    commit(PAGINATED_GROUPS, data)
  },
  getGroupById: async ({ commit }, groupIdparam) => {
    const { data } = await groupsService.find(groupIdparam)

    commit(GROUP_BY_ID, data)
  },
  setSelectedParticipants: ({ commit }, selectedStudents) => {
    commit(SELECTED_STUDENTS, selectedStudents)
  },
  clearSelectedParticipants: ({ commit }) => {
    commit(SELECTED_STUDENTS, [])
  },
  setSelectedCandidates: ({ commit }, selectedStudents) => {
    commit(SELECTED_CANDIDATES, selectedStudents)
  },
  clearSelectedCandidates: ({ commit }) => {
    commit(SELECTED_CANDIDATES, [])
  },
  setShowTourGroupAfterCreate: ({ commit }, isShowTourGroupAfterCreate) => {
    commit(SHOW_TOUR_GROUP_AFTER_CREATE, isShowTourGroupAfterCreate)
  },
}
