import * as types from './types'

export default {
  [types.CLASSROOMS](state, classrooms) {
    state.classrooms = classrooms
  },
  [types.SET_PAGINATED_CLASSROOMS](state, paginatedClassrooms) {
    state.paginatedClassrooms = paginatedClassrooms
  },
  [types.SET_PAGINATED_CLASSROOMS_STUDENTS](state, paginatedClassroomsStudents) {
    state.paginatedClassroomsStudents = paginatedClassroomsStudents
  },
  [types.SET_PAGINATED_CLASSROOMS_TEACHERS](state, paginatedClassroomsTeachers) {
    state.paginatedClassroomsTeachers = paginatedClassroomsTeachers
  },
  [types.CLASSROOM_BY_ID](state, data) {
    state.classroomById = data
  },
  [types.SET_LOADING_CLASSROOMS](state, loadingClassrooms) {
    state.loadingClassrooms = loadingClassrooms
  },
  [types.SET_UPDATED_CLASSROOM](state, updatedClassroom) {
    state.updatedClassroom = updatedClassroom
  },
  [types.SET_UPDATE_IMPORT_CONFLICT](state, result) {
    state.importClassroomResult = result
  },
  [types.DELETE_IMPORT_CLASSROOM_CONFLICT](state, idToRemove) {
    state.importClassroomResult.successCount = 0
    state.importClassroomResult.errorList.splice(idToRemove, 1)
  },
  [types.SET_SPECIFIC_IMPORT_CONFLICT](state, conflictToUpdate) {
    state
      .importClassroomResult
      .errorList[conflictToUpdate.index]
      .body = conflictToUpdate.conflict.body

    state
      .importClassroomResult
      .errorList[conflictToUpdate.index]
      .status = conflictToUpdate.conflict.status
  },
  [types.SET_RESOLVE_IMPORT_CONFLICT](state, index) {
    state.importClassroomResult.errorList[index].resolved = true
  },
  [types.SET_RESET_IMPORT_CONFLICT](state) {
    state.importClassroomResult = {
      successCount: 0,
      alreadyCreatedCount: 0,
      errorList: [],
    }
  },
}
