import * as types from './types'

export default {
  [types.SET_LOADING_STUDENTS](state, loadingStudents) {
    state.loadingStudents = loadingStudents
  },
  [types.SET_STUDENTS_PAGED](state, studentsPaged) {
    state.studentsPaged = studentsPaged
  },
  [types.SET_STUDENTS_UPDATED](state, studentsUpdated) {
    state.studentsUpdated = studentsUpdated
  },
  [types.SET_TYPE_UPDATE](state, typeUpdate) {
    state.typeUpdate = typeUpdate
  },
  [types.SET_SELECT_STUDENT](state, selectedStudent) {
    state.selectedStudents.push(selectedStudent)
  },
  [types.SET_RESET_STUDENT](state) {
    state.selectedStudents = []
  },
  [types.SET_REMOVE_STUDENT](state, selectedStudent) {
    const idToRemove = state.selectedStudents
      .findIndex((student) => student.studentId === selectedStudent.studentId)
    state.selectedStudents.splice(idToRemove, 1)
  },
  [types.SET_NEW_LIST](state, newList) {
    state.selectedStudents = newList
  },
  [types.SET_UPDATE_IMPORT_CONFLICT](state, result) {
    state.importStudentResult = result
  },
  [types.DELETE_IMPORT_STUDENT_CONFLICT](state, idToRemove) {
    state.importStudentResult.successCount = 0
    state.importStudentResult.errorList.splice(idToRemove, 1)
  },
  [types.SET_SPECIFIC_IMPORT_CONFLICT](state, conflictToUpdate) {
    state
      .importStudentResult
      .errorList[conflictToUpdate.index]
      .body = conflictToUpdate.conflict.body

    state
      .importStudentResult
      .errorList[conflictToUpdate.index]
      .status = conflictToUpdate.conflict.status

    state.importStudentResult
      .errorList[conflictToUpdate.index]
      .similarStudents = conflictToUpdate.conflict.similarStudents
  },
  [types.SET_RESOLVE_IMPORT_CONFLICT](state, index) {
    state.importStudentResult.errorList[index].resolved = true
  },
  [types.SET_RESET_IMPORT_CONFLICT](state) {
    state.importStudentResult = {
      successCount: 0,
      alreadyCreatedCount: 0,
      errorList: [],
    }
  },
  [types.DELETED_STUDENT_ID](state, deletedStudentId) {
    state.deletedStudentId = deletedStudentId
  },
  [types.SET_SHOW_STUDENTS_IMPORT](state, showStudentImport) {
    state.showStudentImport = showStudentImport
  },
}
