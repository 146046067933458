export default {
  loadingManager: (state) => state.loadingManager,
  managersPaged: (state) => state.managersPaged,
  selectedManagers: (state) => state.selectedManagers,
  deletedManagerId: (state) => state.deletedManagerId,
  updatedManager: (state) => state.updatedManager,
  deletedManagersIds: (state) => state.deletedManagersIds,
  managementProfiles: (state) => state.managementProfiles,
  managerSelected: (state) => state.managerSelected,
}
