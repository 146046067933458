export default {
  loadingTeacher: (state) => state.loadingTeacher,
  teachersPaged: (state) => state.teachersPaged,
  selectedTeachers: (state) => state.selectedTeachers,
  deletedTeacherId: (state) => state.deletedTeacherId,
  deletedTeachersIds: (state) => state.deletedTeachersIds,
  teacherIdEdit: (state) => state.teacherIdEdit,
  teacherLogin: (state) => state.teacherLogin,
  teacherIdUpdated: (state) => state.teacherIdUpdated,
  gradesWithoutContracts: (state) => state.gradesWithoutContracts,
}
