import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import classroom from './modules/classroom'
import groups from './modules/groups'
import configuration from './modules/configuration'
import contractException from './modules/contractException'
import contracts from './modules/contracts'
import design from './modules/design'
import manager from './modules/manager'
import onboarding from './modules/onboarding'
import school from './modules/school'
import student from './modules/student'
import teacher from './modules/teacher'
import typeform from './modules/typeform'
import breadcrumbs from './modules/breadcrumbs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    contracts,
    classroom,
    design,
    onboarding,
    student,
    school,
    teacher,
    manager,
    configuration,
    contractException,
    typeform,
    groups,
    breadcrumbs,
  },
  strict: process.env.NODE_ENV !== 'production',
})
