<template>
  <Wrapper class="timeout-container">
    <template v-if="offlineConfirmed">
      <h1 class="text-center">
        Tempo esgotado
      </h1>
      <p>
        Não foi possível carregar essa página. Verifique sua conexão e tente novamente.
      </p>
      <p>
        Se o erro persistir, entre em contato.
      </p>
    </template>

    <template v-if="haveError">
      <h1>Ocorreu um erro inesperado</h1>
    </template>

    <LoadingView v-else />
  </Wrapper>
</template>

<script>
import { Wrapper } from '@sas-te/alfabeto-vue'
import axios from 'axios'

import LoadingView from '@/views/Loading'

import Storage from '@/utils/storage'

const checkConnection = () => axios.head(`${window.location.protocol}//${window.location.host}`)

export default {
  name: 'Timeout',
  components: {
    Wrapper,
    LoadingView,
  },
  data() {
    return {
      offlineConfirmed: false,
      haveError: false,
    }
  },
  async beforeCreate() {
    try {
      await checkConnection()

      if (Storage.getItem('justReloaded') === true) {
        this.haveError = true

        Storage.removeItem('justReloaded')

        return
      }

      Storage.setItem('justReloaded', true)

      window.location.reload()
    } catch {
      this.offlineConfirmed = true
    }
  },
}
</script>

<style lang="scss" scoped>
.timeout-container {
  padding-top: $size-xl;
  padding-bottom: $size-xl;
}
</style>
